export default{
  // General
  'About {appName}': 'Acerca de {appName}',
  'About Enable Banking API': 'Acerca de Enable Banking API',
  'Application description is not provided.': 'No se proporciona la descripción de la aplicación.',
  'Authenticate': 'Autenticar',
  'Authentication method': 'Método de autentificación',
  'Authentication session has expired': 'La sesión de autenticación ha expirado',
  'Cancel': 'Cancelar',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Haga clic en el botón {0} si ha confirmado la autorización en la aplicación BankID.',
  'Continue authentication': 'Continuar autenticación',
  'Enter your OTP code with index {otpIndex}': 'Ingrese su código OTP con el índice {otpIndex}',
  'Error during authentication:': 'Error durante la autenticación:',
  'Error while updating authentication status': 'Error al actualizar el estado de autenticación',
  'Open the BankID app on this device': 'Abra la aplicación BankID en este dispositivo',
  'OTP code': 'Código OTP',
  'Redirecting to your account servicing provider': 'Redirigir a su proveedor de servicios de cuenta',
  'Returning to the authentication initiator': 'Volviendo al iniciador de autenticación',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Inicie la aplicación BankID en su dispositivo móvil y toque el botón del código QR en la aplicación para escanear el código QR en esta página',
  'or': 'o',
  'Starting authorization': 'Autorización de inicio',
  'Start typing for filtering the list': 'Comience a escribir para filtrar la lista',
  'To continue please use your authentication app': 'Para continuar, utilice su aplicación de autenticación',
  'Waiting for completion of authentication': 'Esperando a que se complete la autenticación',
  'Try again': 'Intentar otra vez',
  'Submit': 'Entregar',
  'Unable to access server': 'No se puede acceder al servidor',
  'Unable to authenticate': 'Imposible de autenticar',
  'Unable to continue authorization': 'No se puede continuar con la autorización',
  'Unable to continue decoupled authorization': 'No se puede continuar con la autorización desacoplada',
  'Unable to handle authentication status "{status}"': 'No se puede manejar el estado de autenticación "{status}"',
  'Unknown error occurred': 'Ocurrió un error desconocido',
  'You need to close this window': 'Tienes que cerrar esta ventana',
  // Consent
  'Authentication is initiated by {0}.': 'La autenticación es iniciada por {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Después de completar la autenticación, {0} se compartirá con el iniciador de la autenticación.',
  'your payment accounts data': 'los datos de tus cuentas de pago',
  'the list of your payment accounts': 'la lista de sus cuentas de pago',
  'the list of your payment accounts and accounts balances': 'la lista de sus cuentas de pago y saldos de cuentas',
  'the list of your payment accounts and accounts transactions': 'la lista de sus cuentas de pago y transacciones de cuentas',
  'the list of your payment accounts, accounts balances and transactions': 'la lista de sus cuentas de pago, saldos de cuentas y transacciones',
  'By pressing {0} button you agree with the {1}.': 'Al pulsar el botón {0}, acepta {1}.',
  'terms of Enable Banking API service': 'términos del servicio Enable Banking API',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Pulse el botón {0} si no desea compartir los datos de sus cuentas de pago.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API es un servicio en línea que le permite utilizar los datos de su cuenta de pago de un banco elegido o una organización similar (Proveedor de servicios de pago de servicios de cuenta, ASPSP) en otros servicios digitales.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Para utilizar los datos de su cuenta de pago, debe autenticarse en el servicio ASPSP.',
  'The service was formerly known as Tilisy business API.': 'El servicio se conocía anteriormente como Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Su consentimiento explícito para usar los datos de la cuenta de pago se mantendrá en Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Este servicio registra sus tokens de autenticación de PSU y su ID de consentimiento.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Una vez que haya dado su consentimiento en el servicio ASPSP, Enable Banking API abre el acceso a los datos de su cuenta de pago para que el servicio digital que está utilizando (es decir, el iniciador de autenticación) pueda recuperar estos datos.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Los datos de su cuenta de pago fluyen a través de Enable Banking API y no se registrarán allí.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API es propiedad y está operado por Enable Banking Oy, ubicado en Otakaari 5, 02150 Espoo, Finlandia, ID de empresa 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'La empresa es un proveedor de servicios registrado (Proveedor de Servicios de Información de Cuenta, AISP) y está supervisado por la Autoridad de Supervisión Financiera de Finlandia (FIN-FSA).',
  'The registration details can be found from {0}.': 'Los detalles de registro se pueden encontrar en {0}.',
  'the FIN-FSA register for payment services providers': 'el registro FIN-FSA para proveedores de servicios de pago',
  'Please read the {0} carefully before continuing to authentication.': 'Lea atentamente {0} antes de continuar con la autenticación.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Puede consultar los términos y la información adicional el servicio de Enable Banking API en cualquier momento desde {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'La información y las actualizaciones sobre el servicio o la actividad fraudulenta o correspondiente que amenaza el uso seguro del servicio le serán comunicadas por su proveedor de servicios digitales junto con Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Términos de servicio',
  'Last updated:': 'Última actualización:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Lea estos términos de servicio detenidamente antes de utilizar Enable Banking API.',
  'Description of the service': 'Descripción del servicio',
  'Processing of personal data': 'Tratamiento de datos personales',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking procesa datos personales solo con el propósito y en la medida en que el servicio de Enable Banking API pueda funcionar para usted.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'En el tratamiento de datos personales Enable Banking API se compromete a cumplir con la legislación relativa al tratamiento de datos personales y protección de datos así como con el resto de legislación aplicable y en especial el Reglamento General de Protección de Datos de la UE (UE 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Los contenidos más detallados del procesamiento de datos personales, incluyendo:',
  '(a) the nature and purpose of the processing;': '(a) la naturaleza y el propósito del procesamiento;',
  '(b) the type of personal data and categories of data subjects;': '(b) el tipo de datos personales y categorías de interesados;',
  '(c) the applicable data protection measures;': '(c) las medidas de protección de datos aplicables;',
  '(d) the customer’s rights and responsibilities;': '(d) los derechos y responsabilidades del cliente;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'y (e) el objeto y la duración del tratamiento de los datos personales se describirán con mayor detalle en la Política de Privacidad.',
  'Links to other websites': 'Enlaces a otros sitios web',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking puede contener enlaces o redirigirlo a sitios web o servicios de terceros que no son propiedad ni están controlados por la Compañía, incluidos, entre otros, los sitios web o servicios de ASPSP.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'La Compañía no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web o servicios de terceros.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Además, reconoce y acepta que la Compañía no será responsable, directa o indirectamente, de ningún daño o pérdida causados o presuntamente causados por o en relación con el uso o la confianza en dicho contenido, bienes o servicios disponibles en o a través de dichos sitios web o servicios.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Le recomendamos encarecidamente que lea los términos y condiciones y las políticas de privacidad de cualquier sitio web o servicio de terceros que visite.',
  'No warranty': 'Sin garantía',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Sin limitar lo anterior, ni la Compañía ni ninguno de los proveedores de la compañía hace ninguna representación o garantía de ningún tipo, expresa o implícita:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) en cuanto a la operación o disponibilidad del Servicio, o la información, contenido y materiales o productos incluidos en el mismo;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) que el Servicio será ininterrumpido o libre de errores;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) en cuanto a la precisión, confiabilidad o actualidad de cualquier información o contenido proporcionado a través del Servicio.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Algunas jurisdicciones no permiten la exclusión de ciertos tipos de garantías o limitaciones de los derechos legales aplicables de un consumidor, por lo que es posible que algunas o todas las exclusiones y limitaciones anteriores no se apliquen en su caso.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Pero en tal caso, las exclusiones y limitaciones establecidas en esta sección se aplicarán en la mayor medida exigible según la ley aplicable.',
  'Changes': 'Cambios',
  'The Company reserves the right to modify or replace the Service at any time.': 'La Compañía se reserva el derecho de modificar o reemplazar el Servicio en cualquier momento.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Si una revisión es importante, la Compañía hará todos los esfuerzos razonables para notificarle antes de que los nuevos términos entren en vigencia junto con otros proveedores de servicios digitales.',
  'You can also check any updates from this page.': 'También puede consultar cualquier actualización desde esta página.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Al continuar accediendo o utilizando el Servicio después de que esas revisiones entren en vigencia, usted acepta estar sujeto a los términos revisados.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Si no está de acuerdo con los nuevos términos, en su totalidad o en parte, deje de usar el Servicio.',
  'Termination': 'Terminación',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Si por alguna razón ya no deseas usar el Servicio para compartir los datos de tus cuentas de pago con otros servicios digitales, por favor {0}.',
  'visit the Data Sharing Consents page': 'visita la página de Consentimientos de Compartir Datos',
  'Upon termination, your right to use the Service will cease immediately.': 'Tras la rescisión, su derecho a utilizar el Servicio cesará inmediatamente.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Podemos rescindir o suspender su acceso de inmediato, sin previo aviso ni responsabilidad, por cualquier motivo.',
  'Governing law': 'Ley que rige',
  'The laws of Finland shall govern your use of the Service.': 'Las leyes de Finlandia regirán su uso del Servicio.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Si es un consumidor de la Unión Europea, se beneficiará de las disposiciones obligatorias de la ley del país en el que reside.',
  'Disputes resolution': 'Resolución de disputas',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Si tiene alguna inquietud o disputa sobre el Servicio, primero se le pide que intente resolver la disputa de manera informal comunicándose con la Compañía.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'Los usuarios consumidores en Finlandia también tienen derecho a ponerse en contacto con Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'Los asuntos relacionados con la empresa deberán ser dirigidos a la autoridad supervisora.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'La información de contacto de FIN-FSA es: Snellmaninkatu 6, 00100 Helsinki, Finlandia, tel. +358 9 183 51 (centralita); www.finanssivalvonta.fi.',
  'Contact us': 'Contáctenos',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Si tiene alguna pregunta sobre estos términos de servicio, puede comunicarse con nosotros por correo electrónico a {0}.',
  // Backend messages
  '10 digit client number': 'Número de cliente de 10 dígitos',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': 'Número de organización de 10 dígitos (o número personal de 12 dígitos si la empresa es de propiedad única)',
  '10 digit organization number.': 'Número de organización de 10 dígitos.',
  '17 digit card number (starting with 6703)': 'Número de tarjeta de 17 dígitos (a partir de 6703)',
  '6 digit Handelsbanken customer number': 'Número de cliente de Handelsbanken de 6 dígitos',
  '8 digits CVR number': 'Número CVR de 8 dígitos',
  '9 digits Organisasjonsnummer': '9 dígitos Organisasjonsnummer',
  'Agreement number': 'Numero de acuerdo',
  'Agreement number for Nordea Corporate Netbank': 'Número de acuerdo para Nordea Corporate Netbank',
  'Bank user ID': 'ID de usuario del banco',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Código de identidad comercial (Y-tunnus) en formato 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Código de identidad comercial que se muestra en la configuración del banco de Internet corporativo',
  'Client ID': 'Identificación del cliente',
  'Client number': 'Numero de cliente',
  'Company ID': 'ID de la compañía',
  'Company number': 'Numero de compañia',
  'Corporate ID': 'Identificación corporativa',
  'Corporate registration ID': 'Identificación de registro corporativo',
  'Currency code': 'Código de moneda',
  'Digit card number': 'Número de tarjeta de dígitos',
  'Disposer': 'Triturador',
  "Enter your first OTP code with index {otpIndex}": "Introduzca su primer código OTP con índice {otpIndex}",
  "Enter your second OTP code with index {otpIndex}": "Introduzca su segundo código OTP con índice {otpIndex}",
  "Enter the code from SMS sent to {smsCode}": "Introduzca el código del SMS enviado a {smsCode}",
  "Entered OTP code was incorrect, please re-enter code with index {otpIndex}": "El código OTP ingresado fue incorrecto, por favor vuelva a ingresar el código con índice {otpIndex}",
  'E-mail': 'Correo electrónico',
  'E-mail used for authenticating to Holvi': 'Correo electrónico utilizado para autenticarse en Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Ya sea el identificador SEB de 14 dígitos para la empresa o el número de organización de 10 dígitos',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (sin número de subcuenta): número de sucursal de 3 dígitos + número de cliente de 7 dígitos',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Para empresas suecas, este es su número de organización o número SHB, y para comerciantes individuales (Enskild firma), es su número personal, todos los cuales tienen 10 dígitos.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, Pasaporte',
  'Login ID used in MyBRD aplications': 'Identificación de inicio de sesión utilizada en las aplicaciones MyBRD',
  'Login Name': 'Nombre de inicio de sesión',
  'Mandate ID': 'Identificación del mandato',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Número de identidad nacional noruego en el formato DDMMAAXXXXX (11 dígitos)',
  'Online banking PIN': 'PIN de banca en línea',
  'Online banking Password': 'Contraseña de banca en línea',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'ID de la organización en el formato TBxxxxx o TXxxxxx',
  'Organisation number': 'Número de organización',
  'PIN': 'PIN',
  'Password': 'Contraseña',
  'Password for Aktia Internet bank': 'Contraseña para el banco de Internet Aktia',
  'Password used for authenticating to Holvi': 'Contraseña utilizada para autenticarse en Holvi',
  'Personal ID': 'Identificación Personal',
  'Personal ID number': 'Número de identificación personal',
  'Personal code': 'Código personal',
  'Personal code of the user': 'Código personal del usuario',
  'Personal signatory number': 'Número de firmante personal',
  'Personal username for online banking': 'Nombre de usuario personal para la banca en línea',
  'Postbank ID': 'Postbank ID',
  'Social security number (Personnummer)': 'Número de seguro social (Número de persona)',
  'Social security number in the format YYYYMMDDXXXX': 'Número de seguro social en el formato AAAAMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Número de seguridad social sueco en el formato AAAAMMDDXXXX',
  'The IBAN of the user authenticating': 'El IBAN del usuario que se autentica',
  'The currency code': 'El código de moneda',
  'The user ID of the user authenticating': 'El ID de usuario del usuario que se autentica',
  'Unique personal ID number': 'Número de identificación personal único',
  'User ID': 'ID de usuario',
  'User ID for Aktia Internet bank': 'ID de usuario para el banco de Internet Aktia',
  'User ID for Nordea Corporate Netbank': 'ID de usuario para Nordea Corporate Netbank',
  'User ID for online banking': 'ID de usuario para banca en línea',
  'User ID in the format XX000000': 'ID de usuario en el formato XX000000',
  'User ID used to log in to the bank': 'ID de usuario utilizada para iniciar sesión en el banco',
  'Username': 'Nombre de usuario',
  'Username used to log in to the bank': 'Nombre de usuario utilizado para iniciar sesión en el banco',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'Nombre de usuario de WebSSO'
}
