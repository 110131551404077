export default{
  // General
  'About {appName}': 'Informazioni su {appName}',
  'About Enable Banking API': 'Informazioni su Enable Banking API',
  'Application description is not provided.': 'La descrizione dell\'applicazione non è fornita.',
  'Authenticate': 'Autenticare',
  'Authentication method': 'Metodo di autenticazione',
  'Authentication session has expired': 'La sessione di autenticazione è scaduta',
  'Cancel': 'Annulla',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Fai clic sul pulsante {0} se hai confermato l\'autorizzazione nell\'app BankID.',
  'Continue authentication': 'Continua l\'autenticazione',
  'Error during authentication:': 'Errore durante l\'autenticazione:',
  'Error while updating authentication status': 'Errore durante l\'aggiornamento dello stato di autenticazione',
  'Open the BankID app on this device': 'Apri l\'app BankID su questo dispositivo',
  'OTP code': 'Codice OTP',
  'Redirecting to your account servicing provider': 'Reindirizzamento al tuo fornitore di servizi di account',
  'Returning to the authentication initiator': 'Ritorno all\'iniziatore dell\'autenticazione',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Avvia l\'app BankID sul tuo dispositivo mobile e tocca il pulsante del codice QR nell\'app per scansionare il codice QR in questa pagina',
  'or': 'o',
  'Starting authorization': 'Autorizzazione all\'avviamento',
  'Start typing for filtering the list': 'Inizia a digitare per filtrare l\'elenco',
  'To continue please use your authentication app': 'Per continuare utilizza la tua app di autenticazione',
  'Waiting for completion of authentication': 'In attesa del completamento dell\'autenticazione',
  'Try again': 'Riprova',
  'Submit': 'Invia',
  'Unable to access server': 'Impossibile accedere al server',
  'Unable to authenticate': 'Impossibile autenticare',
  'Unable to continue authorization': 'Impossibile continuare l\'autorizzazione',
  'Unable to continue decoupled authorization': 'Impossibile continuare l\'autorizzazione disaccoppiata',
  'Unable to handle authentication status "{status}"': 'Impossibile gestire lo stato di autenticazione "{status}"',
  'Unknown error occurred': 'Errore sconosciuto',
  'You need to close this window': 'Devi chiudere questa finestra',
  // Consent
  'Authentication is initiated by {0}.': 'L\'autenticazione è avviata da {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Dopo aver completato l\'autenticazione, {0} verrà condiviso con l\'iniziatore dell\'autenticazione.',
  'your payment accounts data': 'i dati del tuo conto di pagamento',
  'the list of your payment accounts': 'l\'elenco dei tuoi conti di pagamento',
  'the list of your payment accounts and accounts balances': 'l\'elenco dei conti di pagamento e dei saldi dei conti',
  'the list of your payment accounts and accounts transactions': 'l\'elenco dei tuoi conti di pagamento e movimenti dei conti',
  'the list of your payment accounts, accounts balances and transactions': 'l\'elenco dei tuoi conti di pagamento, i saldi dei conti e le transazioni',
  'By pressing {0} button you agree with the {1}.': 'Premendo il pulsante {0} accetti le {1}.',
  'terms of Enable Banking API service': 'termini del servizio Enable Banking API',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Premi il pulsante {0} se non desideri condividere i dati del tuo account di pagamento.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API è un servizio online che ti consente di utilizzare i dati del tuo conto di pagamento di una banca scelta o di un\'organizzazione simile (Account Servicing Payment Service Provider, l\'ASPSP) in altri servizi digitali.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Per utilizzare i dati del tuo conto di pagamento, devi autenticarti nel servizio ASPSP.',
  'The service was formerly known as Tilisy business API.': 'Il servizio era precedentemente noto come Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Il tuo consenso esplicito all\'utilizzo dei dati del conto di pagamento verrà mantenuto in Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Questo servizio registra i token di autenticazione PSU e l\'ID di consenso.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Dopo aver dato il consenso nel servizio ASPSP, l\'Enable Banking API apre l\'accesso ai dati del tuo conto di pagamento in modo che il servizio digitale che stai utilizzando (ovvero l\'iniziatore dell\'autenticazione) possa recuperare questi dati.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'I dati del tuo conto di pagamento passano attraverso l\'Enable Banking API e non saranno registrati lì.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API è di proprietà ed è gestita da Enable Banking Oy, con sede a Otakaari 5, 02150 Espoo, Finlandia, ID azienda 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'La società è un fornitore di servizi registrato (Account Information Service Provider, AISP) e supervisionato dall\'Autorità di vigilanza finanziaria finlandese (FIN-FSA).',
  'The registration details can be found from {0}.': 'I dettagli di registrazione possono essere trovati da {0}.',
  'the FIN-FSA register for payment services providers': 'il registro FIN-FSA dei prestatori di servizi di pagamento',
  'Please read the {0} carefully before continuing to authentication.': 'Si prega di leggere attentamente {0} prima di continuare con l\'autenticazione.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Puoi controllare i termini e le informazioni aggiuntive sul servizio Enable Banking API in qualsiasi momento da {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Le informazioni e gli aggiornamenti sul servizio o sulle attività fraudolente o corrispondenti che minacciano l\'uso sicuro del servizio ti saranno comunicati dal tuo fornitore di servizi digitali insieme a Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Termini di servizio',
  'Last updated:': 'Ultimo aggiornamento:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Si prega di leggere attentamente questi termini di servizio prima di utilizzare l\'Enable Banking API.',
  'Description of the service': 'Descrizione del servizio',
  'Processing of personal data': 'Trattamento dei dati personali',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking elabora i dati personali solo per lo scopo e nella misura in cui il servizio Enable Banking API può funzionare per te.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Durante il trattamento dei dati personali, Enable Banking API si impegna a rispettare la legislazione in materia di trattamento dei dati personali e protezione dei dati, nonché altre leggi applicabili e in particolare il Regolamento generale sulla protezione dei dati dell\'UE (UE 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'I contenuti più dettagliati del trattamento dei dati personali, tra cui:',
  '(a) the nature and purpose of the processing;': '(a) la natura e le finalità del trattamento;',
  '(b) the type of personal data and categories of data subjects;': '(b) il tipo di dati personali e le categorie di interessati;',
  '(c) the applicable data protection measures;': '(c) le misure di protezione dei dati applicabili;',
  '(d) the customer’s rights and responsibilities;': '(d) i diritti e le responsabilità del cliente;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'e (e) l\'oggetto e la durata del trattamento dei dati personali saranno descritti in maggiore dettaglio nell\'Informativa sulla privacy.',
  'Links to other websites': 'Link ad altri siti web',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking può contenere collegamenti o reindirizzare l\'utente a siti Web o servizi di terze parti che non sono di proprietà o controllati dalla Società, inclusi, a titolo esemplificativo ma non esaustivo, siti Web o servizi di ASPSP.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'La Società non ha alcun controllo e non si assume alcuna responsabilità per il contenuto, le politiche sulla privacy o le pratiche di qualsiasi sito Web o servizio di terze parti.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Riconosci e accetti inoltre che la Società non sarà responsabile, direttamente o indirettamente, per eventuali danni o perdite causati o presumibilmente causati da o in connessione con l\'uso o l\'affidamento a tali contenuti, beni o servizi disponibili su o attraverso tali siti web o servizi.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Ti consigliamo vivamente di leggere i termini e le condizioni e le politiche sulla privacy di qualsiasi sito Web o servizio di terze parti che visiti.',
  'No warranty': 'Nessuna garanzia',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Senza limitare quanto sopra, né la Società né alcuno dei fornitori della società rilascia alcuna dichiarazione o garanzia di alcun tipo, espressa o implicita:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) per quanto riguarda il funzionamento o la disponibilità del Servizio, o le informazioni, i contenuti e i materiali o prodotti inclusi in esso;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) che il Servizio sarà ininterrotto o privo di errori;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) per quanto riguarda l\'accuratezza, l\'affidabilità o l\'attualità di qualsiasi informazione o contenuto fornito attraverso il Servizio.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Alcune giurisdizioni non consentono l\'esclusione di determinati tipi di garanzie o limitazioni sui diritti legali applicabili di un consumatore, pertanto alcune o tutte le esclusioni e limitazioni di cui sopra potrebbero non essere applicabili all\'utente.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Tuttavia, in tal caso, le esclusioni e le limitazioni stabilite nella presente sezione saranno applicate nella misura massima applicabile ai sensi della legge applicabile.',
  'Changes': 'I cambiamenti',
  'The Company reserves the right to modify or replace the Service at any time.': 'La Società si riserva il diritto di modificare o sostituire il Servizio in qualsiasi momento.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Se una revisione è sostanziale, la Società farà ogni ragionevole sforzo per informarti prima che i nuovi termini entrino in vigore insieme ad altri fornitori di servizi digitali.',
  'You can also check any updates from this page.': 'Puoi anche controllare eventuali aggiornamenti da questa pagina.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Continuando ad accedere o utilizzare il Servizio dopo l\'entrata in vigore di tali revisioni, l\'utente accetta di essere vincolato dai termini modificati.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Se non accetti i nuovi termini, in tutto o in parte, ti preghiamo di interrompere l\'utilizzo del Servizio.',
  'Termination': 'Risoluzione',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Se per qualsiasi motivo non desideri più utilizzare il Servizio per condividere i dati del tuo conto di pagamento con altri servizi digitali, per favore {0}.',
  'visit the Data Sharing Consents page': 'visita la pagina dei Consensi di Condivisione dei Dati',
  'Upon termination, your right to use the Service will cease immediately.': 'In caso di risoluzione, il diritto dell\'utente a utilizzare il Servizio cesserà immediatamente.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Possiamo interrompere o sospendere immediatamente il tuo accesso, senza preavviso o responsabilità, per qualsiasi motivo.',
  'Governing law': 'Legge governativa',
  'The laws of Finland shall govern your use of the Service.': 'Le leggi della Finlandia regoleranno l\'utilizzo del Servizio.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Se sei un consumatore dell\'Unione Europea, beneficerai di tutte le disposizioni obbligatorie della legge del paese in cui risiedi.',
  'Disputes resolution': 'Risoluzione delle controversie',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'In caso di dubbi o controversie sul Servizio, in un primo momento, ti viene chiesto di provare a risolvere la controversia in modo informale contattando la Società.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'Gli utenti consumatori in Finlandia hanno anche il diritto di entrare in contatto con il Consumer Disputes Board, Hämeentie 3, P.O. Casella 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'Le questioni relative alla società devono essere indirizzate all\'autorità di vigilanza.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Le informazioni di contatto FIN-FSA sono: Snellmaninkatu 6, 00100 Helsinki, Finlandia, tel. +358 9 183 51 (centralino); www.finanssivalvonta.fi.',
  'Contact us': 'Contattaci',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Se hai domande su questi termini di servizio, puoi contattarci via email all\'indirizzo {0}.',
  // Backend messages
  '10 digit client number': 'Numero cliente a 10 cifre',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': 'Numero dell\'organizzazione a 10 cifre (o numero personale a 12 cifre se la società è una ditta individuale)',
  '10 digit organization number.': 'Numero dell\'organizzazione a 10 cifre.',
  '17 digit card number (starting with 6703)': 'Numero di carta a 17 cifre (a partire da 6703)',
  '6 digit Handelsbanken customer number': 'Numero cliente Handelsbanken a 6 cifre',
  '8 digits CVR number': 'Numero CVR a 8 cifre',
  '9 digits Organisasjonsnummer': '9 cifre Organisasjonsnummer',
  'Agreement number': 'Numero di contratto',
  'Agreement number for Nordea Corporate Netbank': 'Numero di contratto per Nordea Corporate Netbank',
  'Bank user ID': 'ID utente della banca',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Codice identificativo aziendale (Y-tunnus) nel formato 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Codice identificativo aziendale che viene visualizzato nelle impostazioni della banca Internet aziendale',
  'Client ID': 'Identificativo cliente',
  'Client number': 'Numero cliente',
  'Company ID': 'Codice Identificativo dell\'azienda',
  'Company number': 'Numero aziendale',
  'Corporate ID': 'ID aziendale',
  'Corporate registration ID': 'ID di registrazione aziendale',
  'Currency code': 'Codice valuta',
  'Digit card number': 'Digitare il numero della carta',
  'Disposer': 'Smaltitore',
  "Enter your first OTP code with index {otpIndex}": "Inserisci il tuo primo codice OTP con indice {otpIndex}",
  "Enter your second OTP code with index {otpIndex}": "Inserisci il tuo secondo codice OTP con indice {otpIndex}",
  "Enter the code from SMS sent to {smsCode}": "Inserisci il codice dall\'SMS inviato a {smsCode}",
  "Entered OTP code was incorrect, please re-enter code with index {otpIndex}": "Il codice OTP inserito non era corretto, reinserire il codice con indice {otpIndex}",
  'E-mail': 'E-mail',
  'E-mail used for authenticating to Holvi': 'E-mail utilizzata per l\'autenticazione a Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'L\'identificatore SEB a 14 cifre per l\'azienda o il numero dell\'organizzazione a 10 cifre',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (senza numero di sottoconto): numero filiale a 3 cifre + numero cliente a 7 cifre',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Per le società svedesi, questo è il loro numero di organizzazione o numero SHB, e per i commercianti individuali (Enskild firma), è il loro numero personale, che sono tutti di 10 cifre.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'Carta d\'identità, NIE, passaporto',
  'Login ID used in MyBRD aplications': 'ID di accesso utilizzato nelle applicazioni MyBRD',
  'Login Name': 'Nome di login',
  'Mandate ID': 'ID mandato',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Numero di identità nazionale norvegese nel formato GGMMAAXXXXX (11 cifre)',
  'Online banking PIN': 'PIN bancario online',
  'Online banking Password': 'Password bancaria in linea',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'ID organizzazione nel formato TBxxxxx o TXxxxxx',
  'Organisation number': 'Numero dell\'organizzazione',
  'PIN': 'PIN',
  'Password': 'Parola d\'ordine',
  'Password for Aktia Internet bank': 'Password per la banca Internet Aktia',
  'Password used for authenticating to Holvi': 'Password utilizzata per l\'autenticazione a Holvi',
  'Personal ID': 'ID personale',
  'Personal ID number': 'Numero di identificazione personale',
  'Personal code': 'Codice personale',
  'Personal code of the user': 'Codice personale dell\'utente',
  'Personal signatory number': 'Numero di firma personale',
  'Personal username for online banking': 'Nome utente personale per l\'online banking',
  'Postbank ID': 'ID banca postale',
  'Social security number (Personnummer)': 'Numero di previdenza sociale (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Numero di previdenza sociale nel formato AAAAMMGGGXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Numero di previdenza sociale svedese nel formato AAAAMMGGGXXXX',
  'The IBAN of the user authenticating': 'L\'IBAN dell\'utente che si autentica',
  'The currency code': 'Il codice valuta',
  'The user ID of the user authenticating': 'L\'ID utente dell\'utente che esegue l\'autenticazione',
  'Unique personal ID number': 'Numero di identificazione personale univoco',
  'User ID': 'ID utente',
  'User ID for Aktia Internet bank': 'ID utente per la banca Internet Aktia',
  'User ID for Nordea Corporate Netbank': 'ID utente per Nordea Corporate Netbank',
  'User ID for online banking': 'ID utente per l\'online banking',
  'User ID in the format XX000000': 'ID utente nel formato XX000000',
  'User ID used to log in to the bank': 'ID utente utilizzato per accedere alla banca',
  'Username': 'Nome utente',
  'Username used to log in to the bank': 'Nome utente utilizzato per accedere alla banca',
  'VR NetKey': 'NetKey VR',
  'WebSSO user name': 'Nome utente WebSSO'
}
