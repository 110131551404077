export function convertPlaceholdersToKeys (inputString) {
  // Regex to match {key:value} and extract the key
  return inputString.replace(/\{(\w+):[^}]+\}/g, '{$1}')
}

export function getDynamicValues (backendString) {
  const placeholderRegex = /\{(\w+):([^}]+)\}/g
  const replacements = {}

  backendString.replace(placeholderRegex, (_, key, value) => {
    replacements[key] = value
  })

  return Object.keys(replacements).length > 0 ? replacements : {}
}
