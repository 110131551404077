<template>
  <div
    class="enablebanking-auth-flow"
    :key="`${authorization}-${payment}-${internalSessionId}-${internalPaymentId}`"
  >
    <div class="enablebanking-auth-flow-wrapper">
      <div class="text-center">
        <Spinner v-if="loading" />
      </div>
      <AIS
        v-if="internalSessionId"
        :widget-enabled="true"
        @redirect-pis="handleAISRedirect"
      ></AIS>
      <PIS
        v-if="internalPaymentId"
        :widget-enabled="true"
        @redirect-ais="handlePISRedirect"
      ></PIS>
    </div>
    </div>
</template>

<script>
import { i18n } from '../i18n.js'
import AIS from '../pages/AIS.vue'
import PIS from '../pages/PIS.vue'
import Spinner from '../components/elements/Spinner.vue'
import api, { setBaseURL } from '../services/api'
import config from '../config'

export default {
  name: 'enablebanking-auth-flow',
  i18n,
  data () {
    return {
      internalSessionId: null,
      internalPaymentId: null,
      loading: false
    }
  },
  props: {
    origin: {
      type: String
    },
    sandbox: {
      type: Boolean,
      default: false
    },
    authorization: {
      type: String
    },
    payment: {
      type: String
    },
    locale: {
      type: String,
      default: 'EN'
    }
  },
  components: {
    AIS,
    PIS,
    Spinner
  },
  created () {
    let origin = this.origin
    if (!origin) {
      if (this.sandbox) {
        origin = config.publicSandboxOrigin
      } else {
        origin = config.publicOrigin
      }
    }
    setBaseURL(origin)
    this.cleanLocalstorage()
    api.interceptors.request.use(config => { // add sessionid and payment_id to headers
      const xSessionId = localStorage.getItem('X-Session-Id')
      const xPaymentId = localStorage.getItem('X-Payment-Id')
      if (xSessionId) {
        config.headers['X-Session-Id'] = xSessionId
      }
      if (xPaymentId) {
        config.headers['X-Payment-Id'] = xPaymentId
      }
      return config
    })
  },
  async mounted () {
    if (this.locale) {
      this.$i18n.locale = this.locale.toUpperCase()
      window.localStorage.setItem('selected-language', this.locale.toUpperCase())
    }
    if (!this.validateAuthorizationAndPayment()) {
      return
    }
    if (this.authorization) {
      await this.startSession(this.authorization)
    }
    if (this.payment) {
      await this.startPayment(this.payment)
    }
    this.$emit('ready')
  },
  methods: {
    cleanLocalstorage () {
      localStorage.removeItem('X-Session-Id')
      localStorage.removeItem('X-Payment-Id')
    },
    validateAuthorizationAndPayment () {
      if (this.authorization && this.payment) {
        console.error('Error starting session:', 'Both authorization and payment are set')
        this.$emit('error', { message: 'Both authorization and payment are set' })
        return false
      }
      return true
    },
    cleanInternalSessionAndPayment () {
      this.internalSessionId = null
      this.internalPaymentId = null
    },
    switchToAIS (authorization) {
      this.internalSessionId = authorization
      this.$emit('ais-loaded')
    },
    switchToPIS (payment) {
      this.internalPaymentId = payment
      this.$emit('pis-loaded')
    },
    async handlePISRedirect (eventData) {
      const urlObj = new URL(eventData.response.redirect_url)
      const searchParams = new URLSearchParams(urlObj.search)

      const authorization = searchParams.get('sessionid')
      await this.startSession(authorization)
    },
    async handleAISRedirect (eventData) {
      try {
        await api.get(eventData.response.redirect_url + '&widget_call=true') // account_list_auth call
      } catch (error) {
        console.error('Error starting session:', error)
        this.$emit('error', error)
      }
      this.cleanInternalSessionAndPayment()
      this.switchToPIS(this.payment)
    },
    async startSession (authorization) {
      this.cleanInternalSessionAndPayment()
      this.loading = true
      try {
        await api.get(`/ais/start?sessionid=${authorization}&widget_call=true`)
        localStorage.setItem('X-Session-Id', authorization) // set sessionid in localstorage
      } catch (error) {
        console.error('Error starting session:', error)
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
      this.switchToAIS(authorization)
    },
    async startPayment (payment) {
      this.cleanInternalSessionAndPayment()
      this.loading = true
      try {
        await api.get(`/pis/start?payment_id=${payment}&widget_call=true`)
        localStorage.setItem('X-Payment-Id', payment) // set payment_id in localstorage
      } catch (error) {
        console.error('Error starting payment:', error)
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
      this.switchToPIS(payment)
    }
  },
  watch: {
    async authorization (newVal, oldVal) {
      if (!this.validateAuthorizationAndPayment()) {
        return
      }
      await this.startSession(newVal)
    },
    async payment (newVal, oldVal) {
      if (!this.validateAuthorizationAndPayment()) {
        return
      }
      await this.startPayment(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.enablebanking-auth-flow :deep(.enablebanking-auth-flow-wrapper) {
  @import '../style/app.scss';
}
</style>
