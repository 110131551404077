<template>
  <div>
    <div v-if="!bankIdResumeRequired && !bankIdResumed">
      <div v-if="!displayOnly">
        <p class="text-center">
          {{ $t('Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page') }}
        </p>
        <div class="enablebanking-qrcode-container qrcode-container mb-2">
          <canvas id="qrCodeCanvas" class="enablebanking-qrcode-image qrcode-image"></canvas>
        </div>
        <div class="text-center">
          <p class="mb-2 text-center">{{ $t('or') }}</p>
          <a class="btn btn-outline-theme" :href="bankIdAppUri" v-on:click.prevent="openBankIdApp">{{ $t("Open the BankID app on this device") }}</a>
        </div>
      </div>

      <div v-if="displayOnly === 'APP'">
        <div class="text-center">
          <a class="btn btn-outline-theme" :href="bankIdAppUri" v-on:click.prevent="openBankIdApp">{{ $t("Open the BankID app on this device") }}</a>
        </div>
      </div>

      <div v-if="displayOnly === 'IMAGE_LINK' || displayOnly === 'IMAGE_B64'">
        <p class="text-center">
          {{ $t('Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page') }}
        </p>
        <div class="enablebanking-qrcode-container qrcode-container mb-2">
          <img id="qrCodeImage" class="enablebanking-qrcode-image qrcode-image" :src="qrCodeImageUri">
        </div>
      </div>

      <div v-if="displayOnly === 'IMAGE_RENDER'">
        <p class="text-center">
          {{ $t('Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page') }}
        </p>
        <div class="enablebanking-qrcode-container qrcode-container mb-2">
          <canvas id="qrCodeCanvas" class="enablebanking-qrcode-image qrcode-image"></canvas>
        </div>
      </div>

    </div>
    <div v-if="bankIdResumeRequired">
      <i18n-t tag="p" class="text-center" keypath="Click {0} button if you have confirmed authorization in the BankID app.">
        <span>"{{ $t('Continue authentication') }}"</span>
      </i18n-t>
      <div class="text-center">
        <button class="btn btn-theme" v-on:click="resumeBankId">{{ $t("Continue authentication") }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcode'

export default {
  name: 'BankId',
  props: {
    bankIdAppUri: {
      type: String
    },
    displayOnly: {
      type: String
    }
  },
  emits: ['BankIdAppRedirect', 'BankIdResume', 'PageHidden'],
  data () {
    return {
      bankIdResumeRequired: false,
      bankIdResumed: false,
      qrCodeImageUri: '',
      qrCodeImageData: ''
    }
  },
  methods: {
    openBankIdApp () {
      this.bankIdResumeRequired = true
      this.bankIdResumed = false
      const url = new URL(this.bankIdAppUri)
      const userAgent = navigator.userAgent
      let redirect = null
      const blank = false
      // if iOS device
      if (/iphone|ipod|ipad/i.test(userAgent)) {
        if (/fxios/i.test(userAgent)) { // Firefox
          redirect = 'firefox://' // redirect to the firefox browser without opening a new tab
        } else if (/crios/i.test(userAgent)) { // Chrome
          redirect = 'googlechrome://' // redirect to the chrome browser without opening a new tab
        } else if (/version\//i.test(userAgent)) { // Probably Safari
          redirect = 'null' // disable redirect, the BankID app will display the information on the screen
        }
      }
      url.searchParams.set('redirect', redirect)
      this.$emit('BankIdAppRedirect', {
        blank: blank,
        redirectUri: url.href
      })
    },
    displayBankIdQrCode (bankIdAppUri) {
      if (this.displayOnly && ![
        'IMAGE_LINK',
        'IMAGE_RENDER',
        'IMAGE_B64'
      ].includes(this.displayOnly)) {
        return
      }

      let urlWithoutRedirect = bankIdAppUri
      if (bankIdAppUri.startsWith('bankid:///')) {
        const url = new URL(bankIdAppUri)
        url.searchParams.delete('redirect')
        urlWithoutRedirect = url.href
      }
      switch (this.displayOnly) {
        case 'IMAGE_B64':
        case 'IMAGE_LINK':
          this.loadImage(urlWithoutRedirect)
          break
        case 'IMAGE_RENDER':
          this.renderImage(urlWithoutRedirect)
          break
        default:
          this.loadImage(urlWithoutRedirect)
          this.renderImage(urlWithoutRedirect)
      }
    },
    async renderImage (data) {
      const canvas = document.getElementById('qrCodeCanvas')
      if (!canvas) {
        return
      }
      canvas.style.display = 'block'
      try {
        await QRCode.toCanvas(canvas, data)
      } catch (err) {
        console.error('Error generating QR code:', err)
      }
    },
    loadImage (link) {
      this.qrCodeImageUri = link
      this.$nextTick(() => {
        const qrCodeImage = document.getElementById('qrCodeImage')
        if (qrCodeImage) {
          qrCodeImage.style.display = 'block'
        }
      })
    },
    resumeBankId () {
      this.bankIdResumed = true
      this.bankIdResumeRequired = false
      this.$emit('BankIdResume')
    },
    onVisibilitychange (e) {
      const lastVisibilitychange = this.lastVisibilitychange
      const currentTime = new Date().getTime()
      this.lastVisibilitychange = currentTime
      console.log(`${document.visibilityState} ${document.hidden} ${currentTime} ${currentTime - lastVisibilitychange}`)
      const isPageVisible = document.visibilityState === 'visible' || !document.hidden // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState
      const userAgent = navigator.userAgent
      if (!/iphone|ipod|ipad/i.test(userAgent) && this.bankIdResumeRequired && // if not iOS, we can still use auto resume
        isPageVisible &&
        (currentTime - lastVisibilitychange) > 3000) {
        this.resumeBankId()
      }
      if (!isPageVisible) {
        this.$emit('PageHidden')
      }
    }
  },
  mounted () {
    // We don't need to listen to visibilitychange event if we only display the QR code
    if (![
      'IMAGE_LINK',
      'IMAGE_RENDER',
      'IMAGE_B64'
    ].includes(this.displayOnly)) {
      document.addEventListener('visibilitychange', this.onVisibilitychange)
    }
    this.displayBankIdQrCode(this.bankIdAppUri)
  },
  unmounted () {
    document.removeEventListener('visibilitychange', this.onVisibilitychange)
  },
  watch: {
    bankIdAppUri: function (oldUri, newUri) {
      if (newUri && newUri !== oldUri) {
        if (this.bankIdResumed) {
          return
        }
        this.displayBankIdQrCode(newUri)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-image {
  border: 1px solid #ccc;
  display: none;
  width: 228px;
  height: 228px;
}
</style>
