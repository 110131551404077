export default {
  // General
  'About {appName}': 'Über {appName}',
  'About Enable Banking API': 'Über Enable Banking API',
  'Application description is not provided.': 'Anwendungsbeschreibung wird nicht bereitgestellt.',
  'Authenticate': 'Authentifizieren',
  'Authentication method': 'Authentifizierungsmethode',
  'Authentication session has expired': 'Die Authentifizierungssitzung ist abgelaufen',
  'Cancel': 'Absagen',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Klicken Sie auf die Schaltfläche {0}, wenn Sie die Autorisierung in der BankID-App bestätigt haben.',
  'Continue authentication': 'Authentifizierung fortsetzen',
  'Error during authentication:': 'Fehler bei der Authentifizierung:',
  'Error while updating authentication status': 'Fehler beim Aktualisieren des Authentifizierungsstatus',
  'Open the BankID app on this device': 'Öffnen Sie die BankID-App auf diesem Gerät',
  'OTP code': 'OTP-code',
  'Redirecting to your account servicing provider': 'Weiterleitung an Ihren Account Service Provider',
  'Returning to the authentication initiator': 'Rückkehr zum Authentifizierungsinitiator',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Starten Sie die BankID-App auf Ihrem Mobilgerät und tippen Sie in der App auf die Schaltfläche QR-Code, um den QR-Code auf dieser Seite zu scannen',
  'or': 'oder',
  'Starting authorization': 'Startberechtigung',
  'Start typing for filtering the list': 'Beginnen Sie mit der Eingabe, um die Liste zu filtern',
  'To continue please use your authentication app': 'Um fortzufahren, verwenden Sie bitte Ihre Authentifizierungs-App',
  'Waiting for completion of authentication': 'Warten auf abschluss der authentifizierung',
  'Try again': 'Versuchen Sie es nochmal',
  'Submit': 'Einreichen',
  'Unable to access server': 'Serverzugriff nicht möglich',
  'Unable to authenticate': 'Nicht in der Lage zu authentifizieren',
  'Unable to continue authorization': 'Die Autorisierung kann nicht fortgesetzt werden',
  'Unable to continue decoupled authorization': 'Die entkoppelte Autorisierung kann nicht fortgesetzt werden',
  'Unable to handle authentication status "{status}"': 'Authentifizierungsstatus "{status}" kann nicht verarbeitet werden',
  'Unknown error occurred': 'Unbekannter Fehler aufgetreten',
  'You need to close this window': 'Sie müssen dieses Fenster schließen',
  // Consent
  'Authentication is initiated by {0}.': 'Die Authentifizierung wird von {0} initiiert.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Nachdem Sie die Authentifizierung abgeschlossen haben, wird {0} mit dem Authentifizierungsinitiator geteilt.',
  'your payment accounts data': 'Ihre Zahlungskontendaten',
  'the list of your payment accounts': 'die Liste Ihrer Zahlungskonten',
  'the list of your payment accounts and accounts balances': 'die Liste Ihrer Zahlungskonten und Kontostände',
  'the list of your payment accounts and accounts transactions': 'die Liste Ihrer Zahlungskonten und Kontobewegungen',
  'the list of your payment accounts, accounts balances and transactions': 'die Liste Ihrer Zahlungskonten, Kontostände und Transaktionen',
  'By pressing {0} button you agree with the {1}.': 'Durch Drücken der Schaltfläche {0} stimmen Sie der {1} zu.',
  'terms of Enable Banking API service': 'Bedingungen des Enable Banking API-Dienstes',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Drücken Sie die Schaltfläche {0}, wenn Sie Ihre Zahlungskontodaten nicht teilen möchten.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API ist ein Onlinedienst, der es Ihnen ermöglicht, Ihre Zahlungskontodaten einer ausgewählten Bank oder einer ähnlichen Organisation (Account Servicing Payment Service Provider, ASPSP) in anderen digitalen Diensten zu verwenden.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Um Ihre Zahlungskontodaten zu verwenden, müssen Sie sich beim ASPSP-Dienst authentifizieren.',
  'The service was formerly known as Tilisy business API.': 'Der Dienst war früher als Tilisy business API bekannt.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Ihre ausdrückliche Zustimmung zur Verwendung der Zahlungskontodaten wird in Enable Banking API beibehalten.',
  'This service registers your PSU authentication tokens and consent ID.': 'Dieser Dienst registriert Ihre PSU-Authentifizierungstoken und Ihre Zustimmungs-ID.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Sobald Sie die Zustimmung im ASPSP-Dienst erteilt haben, öffnet Enable Banking API den Zugriff auf Ihre Zahlungskontodaten, damit der von Ihnen verwendete digitale Dienst (d. h. der Authentifizierungsinitiator) diese Daten abrufen kann.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Ihre Zahlungskontodaten fließen durch die Enable Banking API und werden dort nicht registriert.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API ist Eigentum und wird betrieben von Enable Banking Oy mit Sitz in Otakaari 5, 02150 Espoo, Finnland, Firmen-ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Das Unternehmen ist ein registrierter Dienstleister (Account Information Service Provider, AISP) und wird von der finnischen Finanzaufsichtsbehörde (FIN-FSA) beaufsichtigt.',
  'The registration details can be found from {0}.': 'Die Registrierungsdetails finden Sie unter {0}.',
  'the FIN-FSA register for payment services providers': 'das FIN-FSA-Register für Zahlungsdienstleister',
  'Please read the {0} carefully before continuing to authentication.': 'Bitte lesen Sie die {0} sorgfältig durch, bevor Sie mit der Authentifizierung fortfahren.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Sie können die Bedingungen und zusätzliche Informationen zum Enable Banking API-Dienstes jederzeit unter {0} einsehen.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Informationen und Updates zu Diensten oder betrügerischen oder entsprechenden Aktivitäten, die die sichere Nutzung des Dienstes bedrohen, werden Ihnen von Ihrem digitalen Dienstanbieter zusammen mit Enable Banking Oy mitgeteilt.',
  // Terms of Service
  'Terms of Service': 'Nutzungsbedingungen',
  'Last updated:': 'Zuletzt aktualisiert:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Bitte lesen Sie diese Nutzungsbedingungen sorgfältig durch, bevor Sie Enable Banking API verwenden.',
  'Description of the service': 'Beschreibung des Dienstes',
  'Processing of personal data': 'Verarbeitung personenbezogener Daten',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking verarbeitet personenbezogene Daten nur zu dem Zweck und in dem Umfang, in dem der Enable Banking API-Dienst für Sie funktionieren kann.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Bei der Verarbeitung personenbezogener Daten verpflichtet sich Enable Banking API, die Gesetze zur Verarbeitung personenbezogener Daten und zum Datenschutz sowie andere anwendbare Gesetze und insbesondere die EU-Datenschutz-Grundverordnung (EU 2016/679) einzuhalten.',
  'The more detailed contents of the personal data processing, including:': 'Die detaillierteren Inhalte der Verarbeitung personenbezogener Daten, einschließlich:',
  '(a) the nature and purpose of the processing;': '(a) Art und Zweck der Verarbeitung;',
  '(b) the type of personal data and categories of data subjects;': '(b) die Art der personenbezogenen Daten und Kategorien betroffener Personen;',
  '(c) the applicable data protection measures;': '(c) die geltenden Datenschutzmaßnahmen;',
  '(d) the customer’s rights and responsibilities;': '(d) die Rechte und Pflichten des Kunden;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'und (e) Gegenstand und Dauer der Verarbeitung personenbezogener Daten werden in der Datenschutzrichtlinie näher beschrieben.',
  'Links to other websites': 'Links zu anderen Websites',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking kann Links enthalten oder Sie zu Websites oder Diensten von Drittanbietern weiterleiten, die nicht Eigentum des Unternehmens sind oder von ihm kontrolliert werden, einschließlich, aber nicht beschränkt auf die Websites oder Dienste von ASPSPs.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Das Unternehmen hat keine Kontrolle über und übernimmt keine Verantwortung für die Inhalte, Datenschutzrichtlinien oder Praktiken von Websites oder Diensten Dritter.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Sie erkennen ferner an und stimmen zu, dass das Unternehmen weder direkt noch indirekt für Schäden oder Verluste verantwortlich oder haftbar ist, die durch oder im Zusammenhang mit der Nutzung oder dem Vertrauen auf solche Inhalte, Waren oder Dienstleistungen, die auf verfügbar sind, verursacht oder angeblich verursacht wurden oder über solche Websites oder Dienste.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Wir empfehlen Ihnen dringend, die Allgemeinen Geschäftsbedingungen und Datenschutzrichtlinien aller Websites oder Dienste Dritter, die Sie besuchen, zu lesen.',
  'No warranty': 'Keine Garantie',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Ohne das Vorstehende einzuschränken, geben weder das Unternehmen noch einer der Anbieter des Unternehmens irgendeine ausdrückliche oder stillschweigende Zusicherung oder Garantie ab:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) in Bezug auf den Betrieb oder die Verfügbarkeit des Dienstes oder der darin enthaltenen Informationen, Inhalte und Materialien oder Produkte;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) dass der Dienst ununterbrochen oder fehlerfrei ist;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) in Bezug auf die Genauigkeit, Zuverlässigkeit oder Aktualität von Informationen oder Inhalten, die über den Dienst bereitgestellt werden.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Einige Gerichtsbarkeiten erlauben den Ausschluss bestimmter Arten von Gewährleistungen oder Beschränkungen der geltenden gesetzlichen Rechte eines Verbrauchers nicht, sodass einige oder alle der oben genannten Ausschlüsse und Beschränkungen möglicherweise nicht auf Sie zutreffen.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'In einem solchen Fall gelten jedoch die in diesem Abschnitt dargelegten Ausschlüsse und Beschränkungen im größtmöglichen Umfang, der nach geltendem Recht durchsetzbar ist.',
  'Changes': 'Änderungen',
  'The Company reserves the right to modify or replace the Service at any time.': 'Das Unternehmen behält sich das Recht vor, den Dienst jederzeit zu ändern oder zu ersetzen.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Wenn eine Überarbeitung wesentlich ist, wird das Unternehmen angemessene Anstrengungen unternehmen, um Sie zusammen mit anderen Anbietern digitaler Dienste zu benachrichtigen, bevor neue Bedingungen in Kraft treten.',
  'You can also check any updates from this page.': 'Auf dieser Seite können Sie auch nach Aktualisierungen suchen.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Indem Sie nach Inkrafttreten dieser Überarbeitungen weiterhin auf den Dienst zugreifen oder ihn nutzen, stimmen Sie zu, an die überarbeiteten Bedingungen gebunden zu sein.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Wenn Sie den neuen Bedingungen ganz oder teilweise nicht zustimmen, beenden Sie bitte die Nutzung des Dienstes.',
  'Termination': 'Beendigung',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Wenn Sie aus irgendeinem Grund den Service nicht mehr nutzen möchten, um Ihre Zahlungskontodaten mit anderen digitalen Diensten zu teilen, besuchen Sie bitte {0}.',
  'visit the Data Sharing Consents page': 'besuchen Sie die Seite zur Datenfreigabe-Einwilligung',
  'Upon termination, your right to use the Service will cease immediately.': 'Mit der Kündigung erlischt Ihr Recht zur Nutzung des Dienstes sofort.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Wir können Ihren Zugang ohne vorherige Ankündigung oder Haftung aus irgendeinem Grund sofort kündigen oder aussetzen.',
  'Governing law': 'Geltendes Recht',
  'The laws of Finland shall govern your use of the Service.': 'Ihre Nutzung des Dienstes unterliegt den Gesetzen Finnlands.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Wenn Sie Verbraucher in der Europäischen Union sind, profitieren Sie von allen zwingenden Bestimmungen des Rechts des Landes, in dem Sie Ihren Wohnsitz haben.',
  'Disputes resolution': 'Streitbeilegung',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Wenn Sie Bedenken oder Streitigkeiten bezüglich des Dienstes haben, werden Sie zunächst gebeten, zu versuchen, die Streitigkeit informell zu lösen, indem Sie sich an das Unternehmen wenden.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'Die Verbrauchernutzer in Finnland sind auch berechtigt, sich an das Verbraucherstreitbeilegungsgremium, Hämeentie 3, P.O. Postfach 306, 00531 Helsinki, Tel. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'Die unternehmensbezogenen Angelegenheiten sind an die Aufsichtsbehörde zu richten.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Die FIN-FSA-Kontaktdaten lauten: Snellmaninkatu 6, 00100 Helsinki, Finnland, Tel. +358 9 183 51 (Zentrale); www.finanssivalvonta.fi.',
  'Contact us': 'Kontaktiere uns',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Wenn Sie Fragen zu diesen Nutzungsbedingungen haben, können Sie uns per E-Mail unter {0} kontaktieren.',
  // Backend messages
  '10 digit client number': '10-stellige Kundennummer',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-stellige Organisationsnummer (oder 12-stellige Personennummer, wenn das Unternehmen ein Einzelunternehmen ist)',
  '10 digit organization number.': '10-stellige Organisationsnummer.',
  '17 digit card number (starting with 6703)': '17-stellige Kartennummer (beginnend mit 6703)',
  '6 digit Handelsbanken customer number': '6-stellige Handelsbanken-Kundennummer',
  '8 digits CVR number': '8-stellige CVR-Nummer',
  '9 digits Organisasjonsnummer': '9-stellige Organisasjonsnummer',
  'Agreement number': 'Übereinkommensnummer',
  'Agreement number for Nordea Corporate Netbank': 'Vertragsnummer für Nordea Corporate Netbank',
  'Bank user ID': 'Bankbenutzer-ID',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Geschäftsidentitätscode (Y-tunnus) im Format 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Geschäftsidentitätscode, der in den Einstellungen der Corporate Internet Bank angezeigt wird',
  'Client ID': 'Kunden ID',
  'Client number': 'Kundennummer',
  'Company ID': 'Firmen-ID',
  'Company number': 'Firmennummer',
  'Corporate ID': 'Firmenausweis',
  'Corporate registration ID': 'Unternehmensregistrierungs-ID',
  'Currency code': 'Währungscode',
  'Digit card number': 'Ziffernkartennummer',
  'Disposer': 'Entsorger',
  "Enter your first OTP code with index {otpIndex}": "Geben Sie Ihren ersten OTP-Code mit Index {otpIndex} ein",
  "Enter your second OTP code with index {otpIndex}": "Geben Sie Ihren zweiten OTP-Code mit Index {otpIndex} ein",
  "Enter the code from SMS sent to {smsCode}": "Geben Sie den Code aus der SMS ein, die an {smsCode} gesendet wurde",
  "Entered OTP code was incorrect, please re-enter code with index {otpIndex}": "Der eingegebene OTP-Code war falsch, bitte geben Sie den Code mit Index {otpIndex} erneut ein",
  'E-mail': 'Email',
  'E-mail used for authenticating to Holvi': 'E-Mail, die zur Authentifizierung bei Holvi verwendet wird',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Entweder die 14-stellige SEB-Kennung des Unternehmens oder die 10-stellige Organisationsnummer',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (ohne Unterkontonummer): 3-stellige Filialnummer + 7-stellige Kundennummer',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Für schwedische Unternehmen ist dies ihre Organisationsnummer oder SHB-Nummer und für Einzelunternehmer (Enskild firma) ist es ihre persönliche Nummer, die alle aus 10 Ziffern besteht.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'Personalausweis, NIE, Reisepass',
  'Login ID used in MyBRD aplications': 'Anmelde-ID, die in MyBRD-Anwendungen verwendet wird',
  'Login Name': 'Benutzername',
  'Mandate ID': 'Mandats-ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norwegische nationale Identitätsnummer im Format TTMMJJXXXXX (11 Ziffern)',
  'Online banking PIN': 'Online-Banking-PIN',
  'Online banking Password': 'Online-Banking-Passwort',
  'Organisation ID in the format TBxxxxx, TXxxxxx, TUxxxxx or NUxxxxx': 'Organisations-ID im Format TBxxxxx oder TXxxxxx oder TUxxxxx oder NUxxxxx',
  'Organisation number': 'Organisationsnummer',
  'PIN': 'PIN',
  'Password': 'Passwort',
  'Password for Aktia Internet bank': 'Passwort für die Aktia Internetbank',
  'Password used for authenticating to Holvi': 'Passwort zur Authentifizierung bei Holvi',
  'Personal ID': 'Personalausweis',
  'Personal ID number': 'Persönliche ID-Nummer',
  'Personal code': 'Persönlicher Code',
  'Personal code of the user': 'Persönlicher Code des Benutzers',
  'Personal signatory number': 'Persönliche Unterzeichnernummer',
  'Personal username for online banking': 'Persönlicher Benutzername für das Online-Banking',
  'Postbank ID': 'Postbank-ID',
  'Social security number (Personnummer)': 'Sozialversicherungsnummer (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Sozialversicherungsnummer im Format JJJJMMTTXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Schwedische Sozialversicherungsnummer im Format JJJJMMTTXXXX',
  'The IBAN of the user authenticating': 'Die IBAN des sich authentifizierenden Benutzers',
  'The currency code': 'Der Währungscode',
  'The user ID of the user authenticating': 'Die Benutzer-ID des authentifizierenden Benutzers',
  'Unique personal ID number': 'Eindeutige persönliche ID-Nummer',
  'User ID': 'Benutzer-ID',
  'User ID for Aktia Internet bank': 'Benutzer-ID für die Aktia Internetbank',
  'User ID for Nordea Corporate Netbank': 'Benutzer-ID für Nordea Corporate Netbank',
  'User ID for online banking': 'Benutzerkennung für Online-Banking',
  'User ID in the format XX000000': 'Benutzer-ID im Format XX000000',
  'User ID used to log in to the bank': 'Benutzer-ID, mit der Sie sich bei der Bank anmelden',
  'Username': 'Nutzername',
  'Username used to log in to the bank': 'Benutzername, mit dem Sie sich bei der Bank anmelden',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO-Benutzername'
}
