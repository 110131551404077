export default{
  // General
  'About {appName}': 'O {appName}',
  'About Enable Banking API': 'O Enable Banking API',
  'Application description is not provided.': 'Nie podano opisu aplikacji.',
  'Authenticate': 'Uwierzytelniać',
  'Authentication method': 'Metoda Uwierzytelnienia',
  'Authentication session has expired': 'Sesja uwierzytelniania wygasła',
  'Cancel': 'anulować',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Kliknij przycisk {0}, jeśli potwierdziłeś autoryzację w aplikacji BankID.',
  'Continue authentication': 'Kontynuuj uwierzytelnianie',
  'Error during authentication:': 'Błąd podczas uwierzytelniania:',
  'Error while updating authentication status': 'Błąd podczas aktualizowania statusu uwierzytelniania',
  'Open the BankID app on this device': 'Otwórz aplikację BankID na tym urządzeniu',
  'OTP code': 'Kod jednorazowy',
  'Redirecting to your account servicing provider': 'Przekierowanie do dostawcy obsługi Twojego konta',
  'Returning to the authentication initiator': 'Powrót do inicjatora uwierzytelniania authentication',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Uruchom aplikację BankID na swoim urządzeniu mobilnym i naciśnij przycisk kodu QR w aplikacji, aby zeskanować kod QR na tej stronie',
  'or': 'lub',
  'Starting authorization': 'Rozpoczęcie autoryzacji',
  'Start typing for filtering the list': 'Zacznij pisać, aby przefiltrować listę',
  'To continue please use your authentication app': 'Aby kontynuować, użyj aplikacji uwierzytelniającej',
  'Waiting for completion of authentication': 'Oczekiwanie na zakończenie uwierzytelniania',
  'Try again': 'Spróbuj ponownie',
  'Submit': 'Zatwierdź',
  'Unable to access server': 'Brak dostępu do serwera',
  'Unable to authenticate': 'Nie można uwierzytelnić',
  'Unable to continue authorization': 'Nie można kontynuować autoryzacji',
  'Unable to continue decoupled authorization': 'Nie można kontynuować autoryzacji oddzielonej od produkcji',
  'Unable to handle authentication status "{status}"': 'Nie można obsłużyć stanu uwierzytelniania „{status}”',
  'Unknown error occurred': 'Wystąpił nieznany błąd',
  'You need to close this window': 'Musisz zamknąć to okno',
  // Consent
  'Authentication is initiated by {0}.': 'Uwierzytelnianie jest inicjowane przez {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Po zakończeniu uwierzytelniania {0} zostaną udostępnione inicjatorowi uwierzytelniania.',
  'your payment accounts data': 'dane Twoich rachunków płatniczych',
  'the list of your payment accounts': 'lista Twoich rachunków płatniczych',
  'the list of your payment accounts and accounts balances': 'listę Twoich rachunków płatniczych i sald rachunków',
  'the list of your payment accounts and accounts transactions': 'listę Twoich rachunków płatniczych i transakcji na rachunkach',
  'the list of your payment accounts, accounts balances and transactions': 'listę Twoich rachunków płatniczych, sald kont i transakcji',
  'By pressing {0} button you agree with the {1}.': 'Naciskając przycisk {0}, zgadzasz się z {1}.',
  'terms of Enable Banking API service': 'warunki korzystania z usługi Enable Banking API',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Naciśnij przycisk {0}, jeśli nie chcesz udostępniać danych swoich rachunków płatniczych.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API to usługa online, która umożliwia wykorzystanie danych Twojego rachunku płatniczego z wybranego banku lub podobnej organizacji (Account Servicing Payment Service Provider, ASPSP) w innych usługach cyfrowych.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Aby korzystać z danych swojego rachunku płatniczego, musisz dokonać uwierzytelnienia w usłudze ASPSP.',
  'The service was formerly known as Tilisy business API.': 'Usługa była wcześniej znana jako Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Twoja wyraźna zgoda na wykorzystanie danych rachunku płatniczego zostanie zachowana w Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Ta usługa rejestruje tokeny uwierzytelniania PSU i identyfikator zgody.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Po wyrażeniu zgody w usłudze ASPSP Enable Banking API otworzy dostęp do danych Twojego rachunku płatniczego, dzięki czemu usługa cyfrowa, z której korzystasz (tj. inicjator uwierzytelniania) będzie mogła te dane pobrać.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Dane Twojego rachunku płatniczego przepływają przez Enable Banking API i nie zostaną tam zarejestrowane.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API jest własnością i jest obsługiwany przez Enable Banking Oy, z siedzibą w Otakaari 5, 02150 Espoo, Finlandia, ID firmy 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Firma jest zarejestrowanym dostawcą usług (Account Information Service Provider, AISP) i jest nadzorowana przez fiński Urząd Nadzoru Finansowego (FIN-FSA).',
  'The registration details can be found from {0}.': 'Szczegóły rejestracji można znaleźć w {0}.',
  'the FIN-FSA register for payment services providers': 'rejestr FIN-FSA dla dostawców usług płatniczych',
  'Please read the {0} carefully before continuing to authentication.': 'Przeczytaj uważnie {0} przed kontynuowaniem uwierzytelniania.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'W każdej chwili możesz sprawdzić warunki i dodatkowe informacje dotyczące usługi Enable Banking API od {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Informacje i aktualizacje dotyczące usług lub oszukańczych lub powiązanych działań zagrażających bezpiecznemu korzystaniu z usługi będą przekazywane użytkownikowi przez dostawcę usług cyfrowych wraz z Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Warunki usługi',
  'Last updated:': 'Ostatnio zaktualizowany:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Prosimy o uważne zapoznanie się z niniejszymi warunkami korzystania z usługi przed użyciem interfejsu Enable Banking API.',
  'Description of the service': 'Opis usługi',
  'Processing of personal data': 'Przetwarzanie danych osobowych',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking przetwarza dane osobowe wyłącznie w celu i w zakresie, w jakim usługa Enable Banking API może dla Ciebie działać.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Podczas przetwarzania danych osobowych Enable Banking API zobowiązuje się do przestrzegania przepisów dotyczących przetwarzania danych osobowych i ochrony danych, a także innych obowiązujących przepisów, w szczególności Ogólnego Rozporządzenia UE o Ochronie Danych (UE 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Bardziej szczegółowe treści dotyczące przetwarzania danych osobowych, w tym:',
  '(a) the nature and purpose of the processing;': '(a) charakter i cel przetwarzania;',
  '(b) the type of personal data and categories of data subjects;': '(b) rodzaj danych osobowych i kategorie osób, których dane dotyczą;',
  '(c) the applicable data protection measures;': '(c) obowiązujące środki ochrony danych;',
  '(d) the customer’s rights and responsibilities;': '(d) prawa i obowiązki klienta;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'oraz (e) przedmiot i czas przetwarzania danych osobowych zostaną szerzej opisane w Polityce Prywatności.',
  'Links to other websites': 'Linki do innych stron internetowych',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking może zawierać łącza lub przekierowywać do stron internetowych lub usług stron trzecich, które nie są własnością ani nie są kontrolowane przez Firmę, w tym między innymi do stron internetowych lub usług ASPSP.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Firma nie ma kontroli nad treścią, polityką prywatności ani praktykami stron internetowych lub usług stron trzecich i nie ponosi za nie odpowiedzialności.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Ponadto przyjmujesz do wiadomości i zgadzasz się, że Firma nie ponosi odpowiedzialności, bezpośrednio ani pośrednio, za jakiekolwiek szkody lub straty spowodowane lub rzekomo spowodowane przez lub w związku z wykorzystaniem lub poleganiem na takich treściach, towarach lub usługach dostępnych na lub za pośrednictwem takich witryn internetowych lub usług.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Zdecydowanie zalecamy zapoznanie się z warunkami i polityką prywatności wszelkich odwiedzanych witryn internetowych lub usług stron trzecich.',
  'No warranty': 'Bez gwarancji',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Nie ograniczając powyższego, ani Firma, ani żaden z jej dostawców nie składa żadnych oświadczeń ani gwarancji jakiegokolwiek rodzaju, wyraźnych lub dorozumianych:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) w zakresie działania lub dostępności Usługi lub zawartych w niej informacji, treści, materiałów lub produktów;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) że Usługa będzie nieprzerwana lub wolna od błędów;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) co do dokładności, wiarygodności lub aktualności jakichkolwiek informacji lub treści dostarczanych za pośrednictwem Usługi.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Niektóre jurysdykcje nie zezwalają na wyłączenie niektórych rodzajów gwarancji lub ograniczeń mających zastosowanie ustawowych praw konsumenta, więc niektóre lub wszystkie powyższe wyłączenia i ograniczenia mogą nie mieć zastosowania.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Jednak w takim przypadku wyłączenia i ograniczenia określone w tej sekcji będą miały zastosowanie w najszerszym zakresie wykonalnym zgodnie z obowiązującym prawem.',
  'Changes': 'Zmiany',
  'The Company reserves the right to modify or replace the Service at any time.': 'Firma zastrzega sobie prawo do modyfikacji lub wymiany Usługi w dowolnym momencie.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Jeśli zmiana jest istotna, Firma podejmie uzasadnione starania, aby powiadomić Cię przed wejściem w życie jakichkolwiek nowych warunków wraz z innymi dostawcami usług cyfrowych.',
  'You can also check any updates from this page.': 'Możesz również sprawdzić wszelkie aktualizacje z tej strony.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Kontynuując dostęp do Usługi lub korzystanie z niej po wejściu w życie tych zmian, wyrażasz zgodę na związanie się zmienionymi warunkami.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Jeśli nie zgadzasz się na nowe warunki, w całości lub w części, zaprzestań korzystania z Usługi.',
  'Termination': 'Zakończenie',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Jeśli z jakiegoś powodu nie chcesz już korzystać z Usługi, aby dzielić się danymi swojego konta płatniczego z innymi usługami cyfrowymi, proszę {0}.',
  'visit the Data Sharing Consents page': 'odwiedź stronę zgody na udostępnianie danych',
  'Upon termination, your right to use the Service will cease immediately.': 'Po rozwiązaniu, Twoje prawo do korzystania z Usługi natychmiast wygasa.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Możemy zakończyć lub zawiesić dostęp użytkownika natychmiast, bez uprzedniego powiadomienia lub odpowiedzialności, z dowolnego powodu.',
  'Governing law': 'Prawo rządowe',
  'The laws of Finland shall govern your use of the Service.': 'Korzystanie z Serwisu podlega prawu Finlandii.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Jeśli jesteś konsumentem z Unii Europejskiej, skorzystasz z wszelkich bezwzględnie obowiązujących przepisów prawa kraju, w którym mieszkasz.',
  'Disputes resolution': 'Rozwiązywanie sporów',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Jeśli masz jakiekolwiek wątpliwości lub spór dotyczący Usługi, najpierw zostaniesz poproszony o próbę rozwiązania sporu w sposób nieformalny, kontaktując się z Firmą.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'Użytkownicy konsumenci w Finlandii mają również prawo do kontaktu z Radą ds. Sporów Konsumenckich, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'Sprawy dotyczące spółki należy kierować do organu nadzoru.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Dane kontaktowe FIN-FSA to: Snellmaninkatu 6, 00100 Helsinki, Finlandia, tel. +358 9 183 51 (centrala); www.finanssivalvonta.fi.',
  'Contact us': 'Skontaktuj się z nami',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Jeśli masz jakiekolwiek pytania dotyczące niniejszych warunków korzystania z usługi, możesz skontaktować się z nami, wysyłając e-mail na adres {0}.',
  // Backend messages
  '10 digit client number': '10-cyfrowy numer klienta',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-cyfrowy numer organizacji (lub 12-cyfrowy numer osobisty, jeśli firma jest jednoosobową działalnością gospodarczą)',
  '10 digit organization number.': '10-cyfrowy numer organizacji.',
  '17 digit card number (starting with 6703)': '17-cyfrowy numer karty (zaczynający się od 6703)',
  '6 digit Handelsbanken customer number': '6-cyfrowy numer klienta Handelsbanken',
  '8 digits CVR number': '8-cyfrowy numer CVR',
  '9 digits Organisasjonsnummer': '9-cyfrowy numer Organisasjons',
  'Agreement number': 'Numer umowy',
  'Agreement number for Nordea Corporate Netbank': 'Numer umowy dla Nordea Corporate Netbank',
  'Bank user ID': 'Identyfikator użytkownika banku',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Kod identyfikacyjny firmy (Y-tunnus) w formacie 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Kod identyfikacyjny firmy wyświetlany w ustawieniach Korporacyjnego banku internetowego',
  'Client ID': 'Identyfikator klienta',
  'Client number': 'Numer klienta',
  'Company ID': 'Identyfikator firmy',
  'Company number': 'Numer firmowy',
  'Corporate ID': 'Numer identyfikacyjny korporacji',
  'Corporate registration ID': 'Identyfikator rejestracyjny firmy',
  'Currency code': 'Kod waluty',
  'Digit card number': 'Cyfrowy numer karty',
  'Disposer': 'Utylizator',
  "Enter your first OTP code with index {otpIndex}": "Wprowadź swój pierwszy kod OTP z indeksem {otpIndex}",
  "Enter your second OTP code with index {otpIndex}": "Wprowadź swój drugi kod OTP z indeksem {otpIndex}",
  "Enter the code from SMS sent to {smsCode}": "Wprowadź kod z SMS-a wysłanego na {smsCode}",
  "Entered OTP code was incorrect, please re-enter code with index {otpIndex}": "Wprowadzony kod OTP był nieprawidłowy, wprowadź kod ponownie z indeksem {otpIndex}",
  'E-mail': 'E-mail',
  'E-mail used for authenticating to Holvi': 'Adres e-mail używany do uwierzytelniania w Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Albo 14-cyfrowy identyfikator SEB firmy, albo 10-cyfrowy numer organizacji',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (bez numeru subkonta): 3-cyfrowy numer oddziału + 7-cyfrowy numer klienta',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'W przypadku szwedzkich korporacji jest to numer organizacji lub numer SHB, a w przypadku osób prowadzących jednoosobową działalność gospodarczą (Enskild firma) jest to numer osobisty składający się z 10 cyfr.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, Paszport',
  'Login ID used in MyBRD aplications': 'Identyfikator logowania używany w aplikacjach MyBRD',
  'Login Name': 'Nazwa użytkownika',
  'Mandate ID': 'Identyfikator mandatu',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norweski krajowy numer identyfikacyjny w formacie DDMMYYXXXXXX (11 cyfr)',
  'Online banking PIN': 'PIN do bankowości internetowej',
  'Online banking Password': 'Hasło do bankowości internetowej',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'Identyfikator organizacji w formacie TBxxxxx lub TXxxxxx',
  'Organisation number': 'Numer organizacji',
  'PIN': 'PIN',
  'Password': 'Hasło',
  'Password for Aktia Internet bank': 'Hasło do banku internetowego Aktia',
  'Password used for authenticating to Holvi': 'Hasło używane do uwierzytelniania w Holvi',
  'Personal ID': 'Dowód osobisty',
  'Personal ID number': 'Osobisty numer identyfikacyjny',
  'Personal code': 'Osobisty kod',
  'Personal code of the user': 'Kod osobisty użytkownika',
  'Personal signatory number': 'Osobisty numer podpisu',
  'Personal username for online banking': 'Osobista nazwa użytkownika do bankowości internetowej',
  'Postbank ID': 'Identyfikator banku pocztowego',
  'Social security number (Personnummer)': 'Numer ubezpieczenia społecznego (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Numer ubezpieczenia społecznego w formacie RRRRMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Szwedzki numer ubezpieczenia społecznego w formacie RRRRMMDDXXXXXX',
  'The IBAN of the user authenticating': 'IBAN użytkownika uwierzytelniającego',
  'The currency code': 'Kod waluty',
  'The user ID of the user authenticating': 'Identyfikator użytkownika uwierzytelniającego',
  'Unique personal ID number': 'Unikalny numer identyfikacyjny',
  'User ID': 'Identyfikator użytkownika',
  'User ID for Aktia Internet bank': 'Identyfikator użytkownika banku internetowego Aktia',
  'User ID for Nordea Corporate Netbank': 'Identyfikator użytkownika dla Nordea Corporate Netbank',
  'User ID for online banking': 'Identyfikator użytkownika do bankowości internetowej',
  'User ID in the format XX000000': 'Identyfikator użytkownika w formacie XX000000',
  'User ID used to log in to the bank': 'Identyfikator użytkownika używany do logowania się do banku',
  'Username': 'Nazwa użytkownika',
  'Username used to log in to the bank': 'Nazwa użytkownika używana do logowania się do banku',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'Nazwa użytkownika WebSSO'
}
